@tailwind base;
@tailwind components;
@tailwind utilities;

/* Add any custom styles here */

/* Audio Tour Modal Styles */
.audio-tour-modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.audio-tour-wrapper {
    position: relative;
    width: 90%;
    height: 90%;
    max-width: 1600px;
}

.audio-tour-container {
    width: 100%;
    height: 100%;
    background-color: white;
    border-radius: 8px;
    overflow: hidden;
}

.audio-tour-iframe {
    width: 100%;
    height: 100%;
    border: none;
}

.audio-tour-close-btn {
    position: absolute;
    top: -15px;
    right: -15px;
    width: 30px;
    height: 30px;
    background-color: white;
    color: #333;
    border: none;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    font-size: 18px;
    font-weight: bold;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
    z-index: 1001;
}

.audio-tour-close-btn:hover {
    background-color: #f0f0f0;
}