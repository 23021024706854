.search-bar-container {
  background-color: #ffffff;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  margin-bottom: 0; /* Changed from 20px to 0 */
  overflow: hidden;
}

.search-bar-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px 20px;
  background-color: #f8f9fa;
  border-bottom: 1px solid #e9ecef;
}

.search-bar-header h2 {
  margin: 0;
  font-size: 1.25rem;
  color: #343a40;
}

.expand-button {
  background: none;
  border: none;
  color: #4a90e2;
  cursor: pointer;
  display: flex;
  align-items: center;
  font-size: 0.9rem;
  transition: color 0.3s ease;
}

.expand-button:hover {
  color: #3a7bc8;
}

.expand-button svg {
  margin-left: 5px;
}

.search-form {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  gap: 15px;
  padding: 20px;
}

.search-input,
.search-select {
  width: 100%;
  padding: 10px 15px;
  border: 1px solid #ced4da;
  border-radius: 4px;
  font-size: 1rem;
  transition: border-color 0.3s ease, box-shadow 0.3s ease;
}

.search-input:focus,
.search-select:focus {
  outline: none;
  border-color: #4a90e2;
  box-shadow: 0 0 0 3px rgba(74, 144, 226, 0.1);
}

.search-button-group {
  display: flex;
  gap: 10px;
}

.search-button {
  flex-grow: 1;
}

.search-button,
.reset-button {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 1rem;
  transition: background-color 0.3s ease;
}

.search-button {
  background-color: #4a90e2;
  color: white;
}

.search-button:hover {
  background-color: #3a7bc8;
}

.reset-button {
  background-color: #6c757d;
  color: white;
}

.reset-button:hover {
  background-color: #5a6268;
}

.search-button svg,
.reset-button svg {
  margin-right: 8px;
}

@media (max-width: 768px) {
  .search-form {
    grid-template-columns: 1fr;
  }
  
  .search-button-group {
    flex-direction: column;
  }
}